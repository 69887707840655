<template>
  <div>
    <el-upload
      ref="upload"
      :name="name"
      :multiple="false"
      :show-file-list="false"
      :before-upload="handleBeforeUpload"
      :auto-upload="false"
      :http-request="httpRequest"
      :style="`background-image:url(${localValue.url?localValue.url:''})`"
      :on-change="showCropper"
      action="#"
      class="logo-upload">
      <div v-show="!localValue.id">
        <i class="company-logo--icon" />
        <div class="el-upload__text">
          {{ $t('company-logo-upload.button.upload') }}
        </div>
      </div>
      <div class="overlay">
        <i
          v-show="localValue.id&&!uploading"
          class="el-icon-delete"
          @click.stop="handleRemove" />
        <i
          v-show="uploading"
          class="el-icon-loading" />
      </div>
    </el-upload>
    <Cropper
      v-if="file"
      :open.sync="openCropper"
      :file="file"
      @cropped="onCropped" />
  </div>
</template>

<script>
import Cropper from '@/components/Cropper'
export default {
  name: 'LogoUpload',
  components: { Cropper },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: '',
          url: '',
          width: '',
          height: ''
        }
      }
    },
    name: {
      type: String,
      default: 'brand'
    }
  },
  data () {
    return {
      localValue: { ...this.value },
      uploading: false,
      openCropper: false,
      file: undefined
    }
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.localValue = { ...nV }
      }
    }
  },
  methods: {
    handleBeforeUpload (file) {
      const allowFileType = ['image/webp', 'image/jpeg', 'image/png']
      const isAllowedFileType = allowFileType.indexOf(file.type) !== -1

      const isLtSize = file.size / 1024 / 1024 <= 2

      if (!isAllowedFileType) {
        this.$message.error(this.$t('company-logo-upload.error.invalid-type'))
        return false
      }
      if (!isLtSize) {
        this.$message.error(this.$t('company-logo-upload.error.invalid-size'))
        return false
      }
      return (isAllowedFileType) && isLtSize
    },
    handleSuccess (res) {
      this.localValue = {
        ...this.localValue,
        id: res.uid,
        url: res.url
      }
      this.onChange()
    },
    handleRemove () {
      this.$confirm(this.$t('company-logo-upload.del-confirm.content'), this.$t('company-logo-upload.del-confirm.title'), {
        confirmButtonText: this.$t('company-logo-upload.del-confirm.confirm'),
        cancelButtonText: this.$t('company-logo-upload.del-confirm.cancel'),
        type: 'warning'
      }).then(() => {
        let action
        if (this.$route.meta.isAdminRoute) {
          action = 'admin/removeFile'
        } else {
          action = 'app/removeFile'
        }
        this.$store.dispatch(action, { fileName: this.localValue.id })
        this.$refs.upload.clearFiles()
        this.localValue = {
          id: '',
          url: '',
          width: '',
          height: ''
        }

        this.onChange()
      })
    },
    onChange () {
      this.$emit('change', this.localValue)
    },
    handleUploadError (err) {
      this.$message.error(err.message || (err.error && err.error.message) || '')
    },
    httpRequest (options) {
      this.uploading = true
      let action
      if (this.$route.meta.isAdminRoute) {
        action = 'admin/uploadLogo'
      } else {
        action = 'app/uploadLogo'
      }

      this.$store.dispatch(action, options).then((response) => {
        const results = response.outlets.results
        if (results.length > 0) {
          this.handleSuccess(results[0]) // LogoUpload only upload one file
        }
      }).catch((error) => {
        this.handleUploadError(error)
      }).finally(() => {
        this.uploading = false
      })
    },
    showCropper (file, fileList) {
      if (this.handleBeforeUpload(file.raw)) {
        this.file = file
        this.openCropper = true
      }
    },
    onCropped ({ blob, width, height }) {
      this.localValue.width = width
      this.localValue.height = height
      this.httpRequest({
        fileName: this.name,
        file: blob
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../styles/bulma-variables";

  .logo-upload {
    width: 240px;
    height: 100px;
    position: relative;
    border-radius: 0;
    border: 1px dashed #555;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: default;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    i {
      font-size: 24px;
      color: $white;
    }

    .overlay {
      opacity: 0;
      border-radius: 0;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 5px;
      }
    }

    &:hover .overlay {
      opacity: 1;
      transition: opacity ease-in-out 0.2s;
    }

    .company-logo--icon {
      background-image: url('../assets/upload-logo-icon.svg');
      background-position: center center;
      background-repeat: no-repeat;
      color: #555;
      display: block;
      height: 16px;
    }
  }

</style>
