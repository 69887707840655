<template>
  <div>
    <el-input
      :value="displayValue"
      type="text"
      placeholder="Amount"
      style="width: 100%"
      @input="formatInput"
      @blur="handleBlur"
      @focus="isInputActive = true" />
  </div>
</template>
<script>
export default {
  name: 'MoneyInput',
  props: {
    value: {
      default: 0,
      type: Number
    }
  },
  data () {
    return {
      isInputActive: false,
      displayValue: ''
    }
  },
  methods: {
    handleBlur (e) {
      this.isInputActive = false
      this.formatInput(e.target.value)
    },
    formatInput (val) {
      if (!val) {
        this.displayValue = undefined
        this.$emit('input', undefined)
        return
      }
      let tmp = val.toString()
      if (tmp.indexOf('.') >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        let decimalPos = tmp.indexOf('.')

        // split number by decimal point
        let leftSide = tmp.substring(0, decimalPos)
        let rightSide = tmp.substring(decimalPos)

        // add commas to left side of number
        leftSide = this.formatNumber(leftSide)

        // validate right side
        rightSide = this.formatNumber(rightSide)

        if (!this.isInputActive) {
          tmp += '00'
        }
        rightSide = rightSide.substring(0, 2)
        tmp = `${leftSide}.${rightSide}`
      } else {
        tmp = this.formatNumber(tmp)
        if (!this.isInputActive) {
          tmp += '.00'
        }
      }
      this.displayValue = tmp
      this.$emit('input', Number(tmp.replace(/[^\d.]/g, '')))
    },
    formatNumber (n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
