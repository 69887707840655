<template>
  <div class="cropper-modal-">
    <PpModal v-model="open">
      <PpCard class="box has-border is-flex">
        <div class="cropper-modal-content">
          <div class="cropper-modal-header">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              {{ $t('components.Cropper.title') }}
            </h3>
          </div>
          <div class="img-cropper">
            <div class="cropper-top" />
            <vue-cropper
              ref="cropper"
              :src="imgSrc"
              :background="false" />
            <div class="cropper-zoom-ctrl">
              <i
                class="cropper-zoom-out"
                @click.prevent="zoomOut">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.25 11.0833V2.91667C12.25 2.275 11.725 1.75 11.0833 1.75H2.91667C2.275 1.75 1.75 2.275 1.75 2.91667V11.0833C1.75 11.725 2.275 12.25 2.91667 12.25H11.0833C11.725 12.25 12.25 11.725 12.25 11.0833ZM4.95833 7.875L6.41667 9.63083L8.45833 7L11.0833 10.5H2.91667L4.95833 7.875Z"
                    fill="white" />
                </svg>
              </i>
              <div class="cropper-slider-wrap">
                <el-slider
                  v-model="zoom"
                  :show-tooltip="false"
                  :min="minZoom"
                  :max="maxZoom"
                  :step="0.1"
                  :zoom-on-touch="false"
                  :zoom-on-wheel="false" />
              </div>
              <i
                class="cropper-zoom-in"
                @click.prevent="zoomIn">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z"
                    fill="white" />
                </svg>
              </i>
            </div>
          </div>
          <div class="cropper-modal-footer has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleCancel">
              {{ $t('views.customer.form.cancel') }}
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 170px;"
              @click="handleSave">
              {{ $t('views.customer.form.save') }}
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'Cropper',
  components: {
    VueCropper
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      imgSrc: '',
      zoom: 1,
      minZoom: 0.1,
      maxZoom: 2
    }
  },
  watch: {
    file: {
      immediate: true,
      handler (nV) {
        if (nV) {
          this.setImage(nV.raw)
        }
      }
    },
    zoom (nV) {
      this.zoomTo(nV)
    }
  },
  methods: {
    handleSave () {
      try {
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(this.$refs.cropper.getCroppedCanvas().prototype, 'toBlob', {
            value: (callback, type, quality) => {
              setTimeout(() => {
                const binStr = atob(this.$refs.cropper.getCroppedCanvas().toDataURL(type, quality).split(',')[1])
                const len = binStr.length
                const arr = new Uint8Array(len)

                for (let i = 0; i < len; i++) {
                  arr[i] = binStr.charCodeAt(i)
                }

                callback(new Blob([arr], { type: type || 'image/png' }))
              })
            }
          })
        }
        const canvasEl = this.$refs.cropper.getCroppedCanvas()
        canvasEl.toBlob((blob) => {
          this.$emit('cropped', {
            blob: blob,
            width: canvasEl.width,
            height: canvasEl.height
          })
        })
        this.zoom = 1
        this.$emit('update:open', false)
      } catch (err) {
        console.log(err)
      }
    },
    handleCancel () {
      this.zoom = 1
      this.$emit('update:open', false)
    },
    setImage (file) {
      if (file.type.indexOf('image/') === -1) {
        this.$message.error('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        this.$message.error('Sorry, FileReader API not supported')
      }
    },
    zoomTo (percent) {
      const containerData = this.$refs.cropper.getContainerData()
      this.$refs.cropper.zoomTo(percent, {
        x: containerData.width / 2,
        y: containerData.height / 2
      })
    },
    zoomIn () {
      this.zoom = this.maxZoom
    },
    zoomOut () {
      this.zoom = this.minZoom
    }
  }
}
</script>

<style lang="scss">
  @import "../styles/bulma-variables";

  .cropper-modal- {
    .pp-modal--content > div {
      padding: 0;
    }
  }

  .cropper-modal-header, .cropper-modal-footer {
    padding: 20px 20px 10px 20px;
  }

  .img-cropper {
    width: 100%;
    @include tablet {
      min-width: 600px;
    }
  }

  .cropper-zoom-ctrl {
    display: flex;
    background: rgba(51, 51, 51, 0.5);
    padding-left: 30px;
    padding-right: 30px;
  }

  .cropper-zoom-out {
    flex: 0 0 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }

  .cropper-zoom-in {
    flex: 0 0 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
  }

  .cropper-slider-wrap {
    flex: 1 1 auto;
  }

  .cropper-modal {
    background-color: rgb(51, 51, 51);
  }

  .cropper-top {
    background-color: rgba(51, 51, 51, 0.5);
    height: 10px;
  }

  .cropper-point {
    background-color: #fff;
    box-shadow: inset 0 0 1px 0px #DEDEDE;
  }

  .cropper-view-box {
    outline: 1px solid #DEDEDE;
  }

  .cropper-slider-wrap {
    .el-slider__runway {
      height: 2px;
      border-radius: 1px;
    }

    .el-slider__bar {
      background-color: $white;
      height: 2px;
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }

    .el-slider__button {
      background: #F5753C;
      border: 2px solid #FFFFFF;
      width: 10px;
      height: 10px;
    }

    .el-slider__button-wrapper {
      top: -16px;
    }
  }

</style>
