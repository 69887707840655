<template>
  <el-form
    ref="localForm"
    :model="localForm"
    class="columns filter-balance-form"
    label-position="top">
    <el-form-item
      class="column"
      prop="dateRange">
      <DatePicker
        v-model="localForm.dateRange"
        :hovering-tooltip="false"
        :min-nights="0"
        :no-min-date="true"
        :display-clear-button="true">
        <template #default="slotProps">
          <el-input
            :placeholder="$t('views.MyAccount.Date range')"
            :value="`${slotProps.date.checkInDate && slotProps.date.checkOutDate ?slotProps.date.checkInDate+' - '+slotProps.date.checkOutDate:''}`"
            readonly />
        </template>
      </DatePicker>
    </el-form-item>

    <el-form-item
      class="column is-narrow"
      prop="action">
      <el-select v-model="localForm.action">
        <el-option
          v-for="item in actionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value" />
      </el-select>
    </el-form-item>

    <div class="column">
      <el-button
        type="primary"
        @click="$emit('searched', localForm)">
        {{ $t('components.SearchGuest.apply') }}
      </el-button>
    </div>
  </el-form>
</template>

<script>
import DatePicker from '@/components/DatePicker/index'
export default {
  name: 'DateRangFilterForm',
  components: { DatePicker },
  props: {
    hideTopUp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localForm: {
        dateRange: {
          startDate: '',
          endDate: ''
        },
        action: ''
      },
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: new Date()
    }
  },
  computed: {
    actionOptions () {
      let actionOptions = [
        {
          label: this.$t('views.MyAccount.Show all'),
          value: ''
        }, {
          label: this.$t('views.MyAccount.action.topUp'),
          value: 'topUp'
        }, {
          label: this.$t('views.MyAccount.action.booking'),
          value: 'booking'
        }, {
          label: this.$t('views.MyAccount.action.refund'),
          value: 'refund'
        }
      ]
      if (this.hideTopUp) {
        actionOptions.splice(1, 1)
      }
      return actionOptions
    }
  }
}
</script>

<style scoped>

</style>
