<template>
  <el-select
    v-model="localValue"
    :placeholder="placeholder"
    filterable
    autocomplete="off">
    <el-option
      v-for="it in showOptions"
      :key="it.value"
      :value="it.value"
      :label="it.label" />
  </el-select>
</template>

<script>

export default {
  name: 'InputShow',
  props: {
    value: {
      type: Boolean,
      default () {
        return true
      }
    },
    placeholder: {
      type: String,
      default: 'Language'
    }
  },
  data () {
    return {
      'showOptions': [
        {
          label: this.$t('showOptions.yes'),
          value: true
        },
        {
          label: this.$t('showOptions.no'),
          value: false
        }
      ]
    }
  },
  computed: {
    localValue: {
      set (v) {
        this.$emit('input', v)
        return v
      },
      get () {
        return this.value
      }
    }
  }
}
</script>

<style scoped>

</style>
